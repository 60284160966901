import React from 'react';
import styled from 'styled-components';
import { Flex } from '../system';

const PartnerLogoImg = styled.img`
    max-width: 212px;
    max-height: 80px;
    width: auto;
    height: auto;
`;

export const PartnerLogo = props => {
    return (
        <Flex justifyContent="space-around">
            <PartnerLogoImg {...props} />
        </Flex>
    );
};
