import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import SwiperCore, { Autoplay, Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';
import { CenteredTextWrapper } from '../common';
import { PartnerLogo } from '../common/PartnerLogo';
import { Box, Heading3 } from '../system';

import heldShipping from './PartnerLogos/held.png';
import libertyBlue from './PartnerLogos/liberty-blue.png';
import marlow from './PartnerLogos/marlow.png';
import navoMare from './PartnerLogos/navo-mare.png';
import tsShipping from './PartnerLogos/ts-shipping.png';
import vertom from './PartnerLogos/vertom.png';
import zeaborn from './PartnerLogos/zeaborn.png';
import nsb from './PartnerLogos/nsb.png';
import nord from './PartnerLogos/nord.png';

SwiperCore.use([Navigation, Autoplay]);

const PartnerContainer = styled.div`
    .swiper-wrapper {
        transition-timing-function: linear;
    }

    .swiper-slide {
        width: 212px;
    }
`;

export const Partners = () => {
    const { t } = useTranslation();

    const logos = [
        <PartnerLogo key="zb" src={zeaborn} alt="Zeaborn" />,
        <PartnerLogo key="nb" src={nsb} alt="NSB" />,
        <PartnerLogo key="no" src={nord} alt="Nord" />,
        <PartnerLogo key="ml" src={marlow} alt="Marlow" />,
        <PartnerLogo key="hl" src={heldShipping} alt="Held Shipping" />,
        <PartnerLogo key="lb" src={libertyBlue} alt="Liberty Blue" />,
        <PartnerLogo key="ts" src={tsShipping} alt="TS Shipping" />,
        <PartnerLogo key="nv" src={navoMare} alt="Navo" />,
        <PartnerLogo key="vt" src={vertom} alt="Vertom" />
    ];

    const swiperOptions = {
        loop: true,
        autoplay: {
            delay: 4,
            disableOnInteraction: false
        },
        speed: 3000,
        spaceBetween: 100,
        slidesPerView: 'auto'
    };

    return (
        <PartnerContainer>
            <CenteredTextWrapper>
                <Heading3>{t('index.partners.headline')}</Heading3>
            </CenteredTextWrapper>

            <Box marginTop={{ xs: 8, md: 10 }}>
                <Swiper {...swiperOptions}>
                    {logos.map((logo, i) => (
                        <SwiperSlide key={i}>{logo}</SwiperSlide>
                    ))}
                </Swiper>
            </Box>
        </PartnerContainer>
    );
};
