import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { CenteredTextWrapper } from '../common';
import { Box, get, Heading3 } from '../system';
import VideoPoster from './video_poster.png';

const Wrapper = styled(Box)`
    max-width: 1200px;
    margin: 0 auto;
`;

export const PlayerWrapper = styled(Box)`
    position: relative;
    /* Player ratio: 100 / (1280 / 720) */
    padding-top: 56.25%;

    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }
`;

const PlayIconWrapper = styled(Box)`
    color: ${get('colors.bold-blueish.0')};
    svg {
        width: 150px;
        height: 150px;
    }
`;

export const PlayIcon = () => {
    return (
        <PlayIconWrapper>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
                <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                    clipRule="evenodd"
                />
            </svg>
        </PlayIconWrapper>
    );
};
export const Video = () => {
    const { t } = useTranslation();

    return (
        <Wrapper paddingX={4}>
            <CenteredTextWrapper marginBottom={6}>
                <Heading3>{t('index.video.title')}</Heading3>
            </CenteredTextWrapper>

            <PlayerWrapper>
                <ReactPlayer
                    className="react-player"
                    url="https://vimeo.com/665925856"
                    width="100%"
                    height="100%"
                    playing
                    playIcon={<PlayIcon />}
                    light={VideoPoster}
                />
            </PlayerWrapper>
        </Wrapper>
    );
};
